import { memo, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import responseNotComeButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/responses/resume_card/response_not_come_button_click';
import {
    Action,
    Button,
    Modal,
    Card,
    Text as MagritteText,
    useBreakpoint,
    BottomSheet,
    NavigationBar,
    BottomSheetFooter,
    Cell,
    CellText,
    Radio,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import BlokoButton from 'bloko/blocks/button';
import Info, { InfoPlacement, InfoTheme } from 'bloko/blocks/drop/Info';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import Gap from 'bloko/blocks/gap';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { setNotAppearanceDate } from 'lux/models/candidatesList';
import { useNotification } from 'src/components/Notifications/Provider';
import { resumeCommentAddMessage } from 'src/components/Notifications/ResumeComments';
import { ResumeCardActionsContext } from 'src/components/ResumeCard/Actions/Context';
import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';

import NonAppearanceContent from 'src/components/Employer/NonAppearance/Content';
import useNonAppearance, { NonAppearanceReason } from 'src/components/Employer/NonAppearance/useNonAppearance';

const TrlKeys = {
    didNotAppear: 'vacancyResponse.didNotShowUp',
    didNotAppearMenu: 'vacancyResponse.didNotShowUp.menu',
    title: 'nonAppearanceModal.title',
    subtitle: 'nonAppearanceModal.subTitle',
    submit: 'nonAppearanceModal.submit',
    cancel: 'nonAppearanceModal.cancel',
    reasonRequired: 'nonAppearance.reasonRequired',
    reasons: {
        [NonAppearanceReason.ForceMajeure]: 'nonAppearanceReason.forceMajeure',
        [NonAppearanceReason.ChoseAnotherVacancy]: 'nonAppearanceReason.choseAnother',
        [NonAppearanceReason.Forgot]: 'nonAppearanceReason.forgot',
        [NonAppearanceReason.Other]: 'nonAppearanceReason.other',
    },
};

interface NonAppearanceProps {
    vacancyId: string;
    resumeHash: string;
    isMenuItem?: boolean;
    viewType: string;
    markAsRead?: () => void;
    closeMenu?: () => void;
    onSubmit?: () => void;
}

const NonAppearance: TranslatedComponent<NonAppearanceProps> = ({
    vacancyId,
    resumeHash,
    viewType,
    markAsRead,
    closeMenu,
    onSubmit,
    trls,
}) => {
    const {
        resume: { topicId },
        lastEmployerStateExtName,
    } = useContext(ResumeCardActionsContext);
    const dispatch = useDispatch();
    const [showButton, setShowButton] = useState(true);
    const [isVisible, toggleIsVisible, setIsVisible] = useToggleState(false);
    const { isMobile } = useBreakpoint();
    const { addNotification } = useNotification();

    const submitHandler = useCallback(() => {
        addNotification(resumeCommentAddMessage);
        dispatch(setNotAppearanceDate({ resumeHash }));
        markAsRead && markAsRead();
        setShowButton(false);
        closeMenu?.();
        onSubmit?.();
    }, [addNotification, closeMenu, dispatch, markAsRead, onSubmit, resumeHash]);
    const handleClick = useCallback(() => {
        if (lastEmployerStateExtName && topicId) {
            responseNotComeButtonClick({ vacancyId, topicId, folderName: lastEmployerStateExtName });
        }
        toggleIsVisible();
    }, [lastEmployerStateExtName, topicId, toggleIsVisible, vacancyId]);
    const onClose = useCallback(() => {
        setIsVisible(false);
        closeMenu?.();
    }, [closeMenu, setIsVisible]);

    const { reason, isValid, isLoading, onChangeReason, submitCallback, cancelCallback } = useNonAppearance({
        vacancyId,
        resumeHash,
        onCancel: onClose,
        onSubmit: submitHandler,
    });

    if (!showButton) {
        return null;
    }

    const renderTrigger = () => {
        switch (viewType) {
            case 'link':
                return (
                    <Gap top right>
                        <Link
                            appearance={LinkAppearance.Pseudo}
                            onClick={handleClick}
                            data-qa="non-appearance__trigger"
                        >
                            {trls[TrlKeys.didNotAppear]}
                        </Link>
                    </Gap>
                );
            case 'menu':
                return closeMenu ? (
                    <Card
                        onClick={handleClick}
                        borderRadius={16}
                        padding={12}
                        paddingLeft={16}
                        hoverStyle="neutral"
                        actionCard
                        stretched
                        data-qa="non-appearance__trigger"
                    >
                        <MagritteText style="primary" typography="label-2-regular">
                            {trls[TrlKeys.didNotAppearMenu]}
                        </MagritteText>
                    </Card>
                ) : (
                    <MenuItem onClick={handleClick} data-qa="non-appearance__trigger">
                        {trls[TrlKeys.didNotAppear]}
                    </MenuItem>
                );
            default:
                return (
                    <BlokoButton onClick={handleClick} data-qa="non-appearance__trigger">
                        {trls[TrlKeys.didNotAppear]}
                    </BlokoButton>
                );
        }
    };

    const renderReasons = () => {
        return (
            <div role="radiogroup">
                {Object.values(NonAppearanceReason).map((value) => (
                    <Cell
                        key={value}
                        left={
                            <Radio
                                name="reason"
                                data-qa="non-appearance-form__reason"
                                onChange={() => {
                                    onChangeReason(value);
                                }}
                                invalid={!isValid}
                            />
                        }
                        vertPadding
                    >
                        <CellText>{trls[TrlKeys.reasons[value]]}</CellText>
                    </Cell>
                ))}
            </div>
        );
    };

    if (isMobile || closeMenu) {
        return (
            <>
                {isMobile ? (
                    <BottomSheet
                        data-qa="non-appearance-bottom-sheet"
                        header={
                            <NavigationBar
                                title={trls[TrlKeys.title]}
                                subtitle={trls[TrlKeys.subtitle]}
                                right={<Action icon={CrossOutlinedSize24} onClick={cancelCallback} />}
                                showDivider="always"
                            />
                        }
                        footer={
                            <BottomSheetFooter>
                                <Button
                                    data-qa="non-appearance-form__submit"
                                    mode="primary"
                                    onClick={submitCallback}
                                    loading={isLoading}
                                    disabled={!isValid || !reason}
                                    stretched
                                >
                                    {trls[TrlKeys.submit]}
                                </Button>
                                <Button
                                    data-qa="non-appearance-form__close"
                                    mode="tertiary"
                                    onClick={cancelCallback}
                                    disabled={isLoading}
                                    stretched
                                >
                                    {trls[TrlKeys.cancel]}
                                </Button>
                            </BottomSheetFooter>
                        }
                        visible={isVisible}
                        onClose={cancelCallback}
                    >
                        {renderReasons()}
                    </BottomSheet>
                ) : (
                    <Modal
                        data-qa="non-appearance-modal"
                        title={trls[TrlKeys.title]}
                        titleDescription={trls[TrlKeys.subtitle]}
                        leftButtons={
                            <Button
                                data-qa="non-appearance-form__close"
                                mode="tertiary"
                                onClick={cancelCallback}
                                disabled={isLoading}
                            >
                                {trls[TrlKeys.cancel]}
                            </Button>
                        }
                        rightButtons={
                            <Button
                                data-qa="non-appearance-form__submit"
                                mode="primary"
                                onClick={submitCallback}
                                loading={isLoading}
                                disabled={!isValid || !reason}
                            >
                                {trls[TrlKeys.submit]}
                            </Button>
                        }
                        size="small"
                        visible={isVisible}
                        onClose={cancelCallback}
                        actions={<Action mode="secondary" onClick={cancelCallback} icon={CrossOutlinedSize24} />}
                    >
                        {renderReasons()}
                    </Modal>
                )}
                {renderTrigger()}
            </>
        );
    }

    return (
        <Info
            theme={InfoTheme.Light}
            placement={viewType === 'link' ? InfoPlacement.TopStart : InfoPlacement.BottomEnd}
            show={isVisible}
            onClose={onClose}
            render={() => (
                <NonAppearanceContent
                    vacancyId={vacancyId}
                    resumeHash={resumeHash}
                    onCancel={onClose}
                    onSubmit={submitHandler}
                />
            )}
        >
            {renderTrigger()}
        </Info>
    );
};

export default memo(translation(NonAppearance));
