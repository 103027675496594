import { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { getClosesPossibleDateFormated } from 'Modules/Interview';
import { VacancySearchItem } from 'lux/models/vacancySearch/vacancySearchItem.types';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import {
    FormData,
    VideoCallCommentData,
    VideoCallDateData,
    VideoCallDuration,
    VideoCallDurationData,
    VideoCallInputDateData,
    VideoCallTimeData,
    VideoCallVacancyData,
} from 'src/components/InviteToVideoCall/types';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const GET_URL = '/shards/employer/video_interview/negotiation_vacancies';

declare global {
    interface FetcherGetApi {
        [GET_URL]: {
            queryParams: { resumeHash: string };
            response: { vacancies: VacancySearchItem[] };
        };
    }
}

interface VideoCallData {
    vacancy: VideoCallVacancyData;
    date: VideoCallDateData;
    inputDate: VideoCallInputDateData;
    time: VideoCallTimeData;
    duration: VideoCallDurationData;
    comment: VideoCallCommentData;
    formData: FormData;
    isEmptyVacancyError: boolean;
}

const useVideoCallData = (resumeHash: string): VideoCallData => {
    const { addNotification } = useNotification();
    const [currentAvailableDate, closestPossibleTime] = getClosesPossibleDateFormated();
    const vacancyId = useSelector((state) => state.router.location.query.vacancyId);

    const defaultValues = {
        vacancy: vacancyId || '',
        vacancyList: [],
        date: currentAvailableDate,
        time: closestPossibleTime,
        duration: VideoCallDuration.QuaterAnHour,
        comment: '',
    };

    const [vacancyList, setVacancyList] = useState<VacancySearchItem[]>(defaultValues.vacancyList);
    const [vacancy, setVacancy] = useState(defaultValues.vacancy);
    const [date, setDate] = useState(defaultValues.date);
    const [time, setTime] = useState(defaultValues.time);
    const [duration, setDuration] = useState(defaultValues.duration);
    const [comment, setComment] = useState(defaultValues.comment);

    const [isEmptyVacancyError, setIsEmptyVacancyError] = useState(false);

    const getVacancyList = useCallback(async () => {
        let vacancyListResponse;
        try {
            vacancyListResponse = await fetcher.get('/shards/employer/video_interview/negotiation_vacancies', {
                params: { resumeHash },
            });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            return;
        }
        if (vacancyListResponse.vacancies?.length > 0) {
            setVacancyList(vacancyListResponse.vacancies);
            if (vacancyListResponse.vacancies.length === 1) {
                setVacancy(vacancyListResponse.vacancies[0].vacancyId.toString());
            }
        } else {
            setIsEmptyVacancyError(true);
        }
    }, [addNotification, resumeHash]);

    useEffect(() => {
        void getVacancyList();
    }, [getVacancyList]);

    const setData = function <T>(setFunc: (newValue: T) => void): ChangeEventHandler<HTMLInputElement> {
        return ({ target: { value } }) => setFunc(value as unknown as T);
    };

    const changeVacancy = useCallback(
        (vacancyId: string | number) => {
            if (vacancyList) {
                if (vacancyId) {
                    setVacancy(vacancyId.toString());
                } else {
                    setVacancy('');
                }
            }
        },
        [vacancyList]
    );

    const settersMap = {
        vacancy: changeVacancy,
        date: setDate,
        inputDate: setDate,
        time: setTime,
        duration: setData(setDuration),
        comment: setData(setComment),
    };

    const vacancyName = useMemo(() => {
        if (vacancyList.length && vacancy) {
            return vacancyList.find((item) => item.vacancyId.toString() === vacancy)?.name || '';
        }
        return '';
    }, [vacancy, vacancyList]);

    const formData = {
        vacancyId: vacancy,
        vacancyName,
        date,
        time,
        duration,
        comment,
    };

    return {
        vacancy: { value: vacancy, onChange: settersMap.vacancy, list: vacancyList },
        date: { value: date, onChange: settersMap.date, currentAvailable: currentAvailableDate },
        inputDate: { value: date, onChange: settersMap.inputDate, currentAvailable: currentAvailableDate },
        time: { value: time, onChange: settersMap.time },
        duration: { value: duration, onChange: settersMap.duration },
        comment: { value: comment, onChange: settersMap.comment },
        formData,
        isEmptyVacancyError,
    };
};

export default useVideoCallData;
